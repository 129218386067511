document.addEventListener("DOMContentLoaded", function() {
    document.querySelectorAll('.carousel__logos').forEach(initCarouselLogos);
});

function initCarouselLogos(logosElement) {
    const logoHTML = logosElement.innerHTML.trim();
    let newHTML = '';
    for (let i = 0; i < 20; i++) {
        newHTML += logoHTML;
    }
    logosElement.innerHTML = newHTML;

    const logosWidth = logosElement.scrollWidth;
    let currentPosition = 0;
    const baseSpeed = 0.4; // Vitesse de base
    let speed = baseSpeed;
    let lastScrollPosition = window.pageYOffset;
    let animationFrameId = null;
    let scrollTimeoutId = null;

    function adjustSpeedOnScroll() {
        const currentScrollPosition = window.pageYOffset;
        const scrollSpeed = Math.abs(currentScrollPosition - lastScrollPosition);
        lastScrollPosition = currentScrollPosition;

        // Appliquer un facteur de vitesse différent pour desktop et mobile
        const speedFactor = window.innerWidth > 768 ? 0.5 : 0.25; // Plus rapide sur desktop

        // Ajuster la vitesse de l'animation en fonction de la vitesse de défilement
        speed = baseSpeed + scrollSpeed * speedFactor;

        // Réinitialiser la vitesse après un délai d'inactivité de défilement
        clearTimeout(scrollTimeoutId);
        scrollTimeoutId = setTimeout(function() {
            speed = baseSpeed;
        }, 150); // Délai avant de réinitialiser la vitesse
    }

    function moveLogos() {
        currentPosition -= speed;
        if (Math.abs(currentPosition) >= logosWidth / 20) {
            currentPosition = 0;
        }
        logosElement.style.transform = `translateX(${currentPosition}px)`;
        animationFrameId = requestAnimationFrame(moveLogos);
    }

    moveLogos();
    window.addEventListener('scroll', adjustSpeedOnScroll);

    function clearResources() {
        window.removeEventListener('scroll', adjustSpeedOnScroll);
        clearTimeout(scrollTimeoutId);
        cancelAnimationFrame(animationFrameId);
    }
}
