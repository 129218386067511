import * as THREE from "three";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import { gsap } from "gsap";

document.addEventListener('DOMContentLoaded', () => {
    let scene, camera, renderer, model;
    let modelLoaded = false;
    let mouse = new THREE.Vector2();

    function init() {
        scene = new THREE.Scene();
        const container = document.getElementById('scene-container');
        const width = container.clientWidth;
        const height = container.clientHeight;

        // camera = new THREE.PerspectiveCamera(fov, width / height, near, far);
        camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1000);
        camera.position.z = 3.5; // Vous avez déjà cette valeur pour la profondeur
        camera.position.y = 0;

        renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(width, height);
        container.appendChild(renderer.domElement);
        renderer.setPixelRatio(window.devicePixelRatio);

        const ambientLight = new THREE.AmbientLight(0xffffff, 3); // Augmente l'intensité
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 3); // Augmente l'intensité
        directionalLight.position.set(1, 1, 1);
        scene.add(directionalLight);

        const loader = new GLTFLoader();
        loader.load('assets/3D/pulseWave.gltf', function (gltf) {
            model = gltf.scene;
            model.traverse((child) => {
                model.traverse((child) => {
                    if (child.isMesh && child.material) {
                        child.material.color.setHex(0x4083FF); // Définit la couleur blanche, changez 0xffffff à votre code couleur hexadécimal
                        // Pour plus de précision, vous pouvez également ajuster la réflexion et la rugosité
                        child.material.metalness = 0.4; // Ajuste la métallicité
                        child.material.roughness = 0.4; // Ajuste la rugosité
                    }
                });
            });
            model.scale.set(60, 60, 60); // Ajustez les valeurs d'échelle selon vos besoins
            scene.add(model);
            modelLoaded = true;
            addFloatingEffect();
        }, undefined, function (error) {
            console.error(error);
        });

        window.addEventListener('resize', onWindowResize, false);
        requestOrientationPermission();
        animate();
    }

    function onWindowResize() {
        const container = document.getElementById('scene-container');
        if (container) {
            const width = container.clientWidth;
            const height = container.clientHeight;

            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
        } else {
            console.warn("Element 'scene-container' not found");
        }
    }

    function resizeRendererToDisplaySize(renderer) {
        const canvas = renderer.domElement;
        const width = canvas.parentElement.clientWidth;
        const height = canvas.parentElement.clientHeight;
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
            renderer.setSize(width, height, false);
        }
        return needResize;
    }

    function animate() {
        requestAnimationFrame(animate);

        if (resizeRendererToDisplaySize(renderer)) {
            const canvas = renderer.domElement;
            camera.aspect = canvas.clientWidth / canvas.clientHeight;
            camera.updateProjectionMatrix();
        }

        renderer.render(scene, camera);
    }

    function handleOrientation(event) {
        if (!modelLoaded) return;

        const { alpha, beta, gamma } = event;
        model.rotation.x = beta ? THREE.MathUtils.degToRad(beta) : 0;
        model.rotation.y = gamma ? THREE.MathUtils.degToRad(gamma) : 0;
        model.rotation.z = alpha ? THREE.MathUtils.degToRad(alpha) : 0;
    }

    function requestOrientationPermission() {
        if (typeof DeviceOrientationEvent.requestPermission === 'function') {
            document.getElementById('requestPermissionButton').addEventListener('click', function () {
                DeviceOrientationEvent.requestPermission()
                    .then(permissionState => {
                        if (permissionState === 'granted') {
                            window.addEventListener('deviceorientation', handleOrientation, true);
                        } else {
                            alert('Permission to use device orientation was denied');
                        }
                    })
                    .catch(console.error);
            });
        } else {
            window.addEventListener('deviceorientation', handleOrientation, true);
        }
    }

    init();

    let header = document.getElementById('header');

    header.addEventListener('mousemove', (event) => {
        if (!modelLoaded) return;

        // Notez que nous utilisons ici 'header' au lieu de 'container'
        const rect = header.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / header.clientWidth) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / header.clientHeight) * 2 + 1;

        // Logique de rotation basée sur la position de la souris, ajustée pour le header
        const targetRotationY = (Math.PI * mouse.x) / 20;
        const targetRotationX = -(Math.PI * mouse.y) / 20;

        model.rotation.y += (targetRotationY - model.rotation.y) * 0.1;
        model.rotation.x += (targetRotationX - model.rotation.x) * 0.1;
    });

    function addFloatingEffect() {
        if (!model || !model.position) {
            console.error("Model is not loaded or does not have a position property.");
            return;
        }
        gsap.to(model.position, {
            y: "+=0.2", // Amplitude du flottement
            duration: 2, // Durée d'un cycle complet de flottement
            repeat: -1,
            yoyo: true,
            ease: "sine.inOut"
        });
    }

// Assurez-vous d'appeler cette fonction une fois que votre modèle est chargé et prêt
    addFloatingEffect();
});