import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { animateLinks } from './social';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {
    const paths = document.querySelectorAll('.backgroundAnimation');

    paths.forEach((path, index) => {
        const length = path.getTotalLength();
        path.style.strokeDasharray = length;
        path.style.strokeDashoffset = length;

        if (index === 0) {
            gsap.to(path, {
                strokeDashoffset: 0,
                duration: 1.5,
            });
        } else {
            gsap.fromTo(path, {
                strokeDashoffset: length,
            }, {
                strokeDashoffset: 0,
                duration: 1.5,
                scrollTrigger: {
                    invalidateOnRefresh: true,
                    trigger: path,
                    start: "top bottom",
                    end: "top top",
                    scrub: 1,
                    toggleActions: "play none none none",
                },
            });
        }
    });
});

document.addEventListener('DOMContentLoaded', () => {
    // Configuration initiale pour le défilement horizontal, si nécessaire
    const setupHorizontalScroll = () => {
        const horizontalSection = document.querySelector(".project__container");
        const horizontalContent = document.querySelector(".project__items");
        const totalWidth = horizontalContent.scrollWidth;

        gsap.to(horizontalContent, {
            x: () => -(totalWidth - window.innerWidth),
            ease: "none",
            scrollTrigger: {
                trigger: horizontalSection,
                pin: true,
                scrub: 1,
                start: "top top",
                end: () => "+=" + (totalWidth - window.innerWidth),
                invalidateOnRefresh: true
            }
        });
    };

    // Appliquer les effets parallaxe verticaux pour tous les éléments .background
    const applyVerticalParallax = () => {
        const parallaxContainers = document.querySelectorAll('.project__background-parallax');

        parallaxContainers.forEach((container, index) => {
            const isInHorizontalScrollSection = container.closest('.project') !== null;
            const background = container.querySelector('.background');

            if (isInHorizontalScrollSection && window.innerWidth >= 1200) {
                // Pour le défilement horizontal, calculer le déplacement nécessaire
                const horizontalSection = container.closest('.project__content');
                const totalWidth = horizontalSection.scrollWidth;
                const totalHeight = horizontalSection.scrollHeight;
                let startOffset = (totalWidth * index) - totalHeight;
                let end = totalWidth + totalHeight;

                gsap.to(background, {
                    y: '30%', // Ajuster selon l'effet désiré
                    ease: "none",
                    scrollTrigger: {
                        trigger: horizontalSection,
                        // start: () => `${totalHeight}+=${startOffset} right`,
                        start: () => `${startOffset} right`,
                        end: () => `${end}+=${startOffset}`,
                        scrub: 1,
                        invalidateOnRefresh: true
                    }
                });
            } else {
                // Défilement vertical standard
                gsap.to(background, {
                    y: '30%',
                    scrollTrigger: {
                        trigger: container,
                        start: "top bottom", // Début de l'animation lorsque le conteneur entre en vue
                        end: "bottom top", // Fin de l'animation lorsque le conteneur sort de vue
                        scrub: 1
                    },
                });
            }
        });
    };

    // Activer le défilement horizontal pour les écrans larges
    if(window.innerWidth >= 1200) {
        setupHorizontalScroll();
    }

    // Appliquer l'effet parallaxe vertical à tous les éléments
    applyVerticalParallax();
});

document.addEventListener('DOMContentLoaded', () => {
    ScrollTrigger.create({
        trigger: '#footer',
        start: 'top bottom',
        onEnter: () => animateLinks('.footer__list .footer__list-item'), // Utilisez le sélecteur approprié
        // onEnterBack: () => animateLinks('#footer .social-link-footer'), // Si vous voulez animer lors du scroll inverse
    });
});

// document.addEventListener('DOMContentLoaded', () => {
//     // Sélectionner tous les conteneurs .project__background-parallax
//     const parallaxContainers = document.querySelectorAll('.project__background-parallax');
//
//     // Appliquer l'effet parallaxe à chaque .background dans son propre conteneur .project__background-parallax
//     parallaxContainers.forEach(container => {
//         // GSAP animation pour le .background spécifique dans ce conteneur
//         gsap.to(container.querySelector('.background'), {
//             scrollTrigger: {
//                 trigger: container,
//                 start: "top bottom", // Début de l'animation lorsque le conteneur entre en vue
//                 end: "bottom top", // Fin de l'animation lorsque le conteneur sort de vue
//                 scrub: 1
//             },
//             y: '30%', // Ajustez ce pourcentage pour l'effet parallaxe désiré
//         });
//     });
// });

document.addEventListener('DOMContentLoaded', () => {
    gsap.to('.--p0', {
        scrollTrigger: {
            trigger: '.--p0',
            start: 'top top',
            end: '+=100%', // Ajustez cette valeur selon le comportement souhaité
            scrub: 1,
            pin: true, // Rend la section fixe
        },
        scale: 0.5, // Réduit la taille à 50%
        autoAlpha: 0, // Gère la transparence (combine opacity et visibility)
        ease: 'none'
    });
});

document.addEventListener('DOMContentLoaded', () => {
    // Utilisez querySelectorAll pour cibler tous les éléments avec la classe .gzgzgz
    function changeStyles(add) {
        const action = add ? 'add' : 'remove';
        document.querySelectorAll(".changeBackground").forEach(el => el.classList[action]("--dark__background"));
        document.querySelectorAll(".changeText").forEach(el => el.classList[action]("--dark__text"));
        document.querySelectorAll(".changeBackgroundText").forEach(el => el.classList[action]("--dark__background-text"));
    }

    ScrollTrigger.create({
        trigger: "#project",
        start: "top center",
        end: "bottom+=100 center",
        onEnter: () => changeStyles(true),
        onLeave: () => changeStyles(false),
        onEnterBack: () => changeStyles(true),
        onLeaveBack: () => changeStyles(false),
    });
});

// document.addEventListener('DOMContentLoaded', () => {
//     const customCursor = document.querySelector('.custom-cursor');
//     if (!customCursor) {
//         console.error('Le curseur personnalisé global n\'est pas trouvé.');
//         return;
//     }
//
//     let mouseX = 0, mouseY = 0;
//     let cursorX = 0, cursorY = 0;
//     const speed = 0.1;
//
//     const updateCursor = () => {
//         const dx = mouseX - cursorX;
//         const dy = mouseY - cursorY;
//
//         cursorX += dx * speed;
//         cursorY += dy * speed;
//
//         customCursor.style.left = `${cursorX}px`;
//         customCursor.style.top = `${cursorY}px`;
//
//         requestAnimationFrame(updateCursor);
//     };
//
//     document.querySelectorAll('.project__content-container img').forEach(img => {
//         img.addEventListener('mousemove', e => {
//             const rect = img.getBoundingClientRect();
//             mouseX = e.pageX - rect.left - window.scrollX;
//             mouseY = e.pageY - rect.top - window.scrollY;
//             customCursor.style.display = 'block';
//             requestAnimationFrame(() => {
//                 customCursor.style.transform = 'translate(-50%, -50%) scale(1)';
//             });
//         });
//
//         img.addEventListener('mouseleave', () => {
//             customCursor.style.transform = 'translate(-50%, -50%) scale(0)';
//             setTimeout(() => {
//                 customCursor.style.display = 'none';
//             }, 300);
//         });
//     });
//
//     updateCursor();
// });

// document.addEventListener('DOMContentLoaded', () => {
//     let customCursor; // Référence au curseur personnalisé, sera créé si nécessaire
//     let requestAnimationFrameId; // Pour stocker l'ID de requestAnimationFrame
//
//     // Fonction pour créer/mettre à jour le curseur dans le conteneur actif
//     function createOrUpdateCursor(container) {
//         if (!customCursor) {
//             customCursor = document.createElement('div');
//             customCursor.className = 'custom-cursor';
//         }
//
//         if (customCursor.parentNode !== container) {
//             container.appendChild(customCursor);
//         }
//     }
//
//     let mouseX = 0, mouseY = 0; // Position cible de la souris
//     let cursorX = 0, cursorY = 0; // Position actuelle du curseur
//     const speed = 0.1; // Contrôle la vitesse de "rattrapage" du curseur
//
//     // Fonction pour animer le curseur vers la position de la souris
//     function animateCursor() {
//         // Calcule la différence de position
//         const dx = mouseX - cursorX;
//         const dy = mouseY - cursorY;
//
//         // Incrémente la position du curseur vers la position de la souris
//         cursorX += dx * speed;
//         cursorY += dy * speed;
//
//         // Applique la nouvelle position du curseur
//         if (customCursor) {
//             customCursor.style.left = `${cursorX}px`;
//             customCursor.style.top = `${cursorY}px`;
//         }
//
//         // Planifie la prochaine animation
//         requestAnimationFrameId = requestAnimationFrame(animateCursor);
//     }
//
//     document.querySelectorAll('.project__content-container').forEach(container => {
//         container.addEventListener('mousemove', e => {
//             createOrUpdateCursor(container);
//
//             // Met à jour la position cible en tenant compte du défilement
//             const rect = container.getBoundingClientRect();
//             mouseX = e.clientX - rect.left;
//             mouseY = e.clientY - rect.top;
//
//             customCursor.classList.add('--active');
//         });
//
//         container.addEventListener('mouseenter', () => {
//             // Démarre l'animation quand la souris entre dans un conteneur
//             if (!requestAnimationFrameId) {
//                 animateCursor();
//             }
//         });
//
//         container.addEventListener('mouseleave', () => {
//             customCursor.classList.remove('--active');
//         });
//     });
//
//     // Assurez-vous de démarrer l'animation du curseur au moins une fois
//     animateCursor();
// });

document.addEventListener('DOMContentLoaded', () => {
    const animateTitlePart = (selector, direction = 1, duration = 0.5, stagger = 0.05) => {
        gsap.fromTo(selector, {
            y: () => direction * 100,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            stagger,
            duration,
            ease: "power2.out",
            scrollTrigger: {
                trigger: selector, // Utilisez le sélecteur passé à la fonction
                start: "top bottom-=100",
                toggleActions: "play none none reset",
            },
        });
    };

    // Animation pour la partie supérieure
    // animateTitlePart('.title-part.top span', 1);

    document.querySelectorAll(".title").forEach((title, index) => {
        // Construction du sélecteur pour cibler les parties du titre actuel
        const selector = `.title[data-title="${index + 1}"] .title-part.top span`;
        animateTitlePart(selector, 1); // Vous pouvez ajuster la direction et le décalage si nécessaire
    });

    let size = 0;

    if (window.innerWidth >= 1200) {
        size = 450
    }

    // Animation pour la partie inférieure, suivie d'un décalage vers la droite
    animateTitlePart('.title-part.bottom span', -1, 0.25);
    gsap.to('.title-part.bottom span', {
        x: size, // Décalage vers la droite plus visible
        delay: 0.5, // S'assure que l'animation des lettres est bien terminée
        duration: 0.5,
        stagger: 1,
        ease: "power2.out",
        scrollTrigger: {
            trigger: '.title-part',
            start: "top bottom-=100",
            toggleActions: "play none none reset",
        },
    });

    document.querySelectorAll('.animated-text').forEach(container => {
        const text = container.innerText;
        const words = text.split(/\s+/).map(word => `<span>${word}</span> `);
        container.innerHTML = words.join('');
    });

// Sélectionner tous les conteneurs de texte à animer
    const textContainers = gsap.utils.toArray(".animated-text");

    textContainers.forEach(container => {
        // Pour chaque conteneur, sélectionner les spans à l'intérieur
        const spans = gsap.utils.toArray("span", container);

        // Création d'une timeline avec ScrollTrigger spécifique au conteneur
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: container,
                start: "top bottom", // L'animation démarre quand le haut de l'élément touche le bas du viewport
                end: "top center", // L'animation se termine lorsque le haut de l'élément atteint le centre du viewport
                toggleActions: "play none none reset", // Joue l'animation à l'entrée et la réinitialise si l'élément sort et revient
            }
        });

        // Ajout de chaque span à la timeline avec un décalage très rapide
        spans.forEach((span, i) => {
            tl.to(span, { opacity: 1, y: 0, duration: 0.01 });
        });
    });
});







