import {animateLinks} from "./social";

document.addEventListener('DOMContentLoaded', () => {
    let isMenuOpen = false;
    const menu = document.getElementById('menu');
    const logo = document.getElementById('logo');
    const contact = document.getElementById('contact-button');
    const toggleButton = document.getElementById('toggleMenu');
    const toggleText = document.getElementById('content-text');

    const smoothScrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    const show = (elementId) => {
        isMenuOpen = false;
        toggleText.textContent = 'Menu';
        menu.classList.remove('--shown');
        contact.classList.remove('--hidden');

        document.querySelectorAll('.header__menu-social .social-link').forEach(link => {
            link.classList.remove('animate-in');
        });

        setTimeout(() => {
            menu.classList.add('--hidden');
            document.body.classList.remove('--no-scroll');
        }, 500);

        setTimeout(() => {
            logo.classList.remove('--active', '--hidden');
            toggleButton.classList.remove('--active', '--hidden');
        }, 250);

        // Scroll to element if provided
        if (elementId) {
            setTimeout(() => smoothScrollToElement(elementId), 200); // Wait for the menu to fully hide
        }
    };

    const hidden = () => {
        isMenuOpen = true;
        toggleText.textContent = 'Fermer';
        menu.classList.remove('--hidden');
        contact.classList.add('--hidden');
        setTimeout(() => {
            menu.classList.add('--shown');
        }, 20);

        document.body.classList.add('--no-scroll');

        logo.classList.add('--hidden');
        toggleButton.classList.add('--hidden');

        setTimeout(() => {
            // Assuming animateLinks is a function you have for animating links, but not shown in the snippet you provided.
            animateLinks('.header__menu-social .social-link');
            logo.classList.add('--active');
            toggleButton.classList.add('--active');
            setTimeout(() => {
                logo.classList.remove('--hidden');
                toggleButton.classList.remove('--hidden');
            }, 500);
        }, 500);
    };

    // Modified to prevent default action
    document.getElementById('toggleMenu').addEventListener('click', function(event) {
        event.preventDefault();
        if (menu.classList.contains('--shown')) {
            show();
        } else {
            hidden();
        }
    });

    // Setup event listeners for menu items and prevent default action
    document.querySelectorAll('.button-navigation').forEach(item => {
        item.addEventListener('click', function(event) {
            event.preventDefault();
            const targetSection = this.getAttribute('data-target');
            show(targetSection); // Pass the ID of the target section
        });
    });

    // Setup event listeners for external buttons and prevent default action
    document.querySelectorAll('.external-link-button').forEach(button => {
        button.addEventListener('click', function(event) {
            event.preventDefault();
            const targetSection = this.getAttribute('data-target');
            if (menu.classList.contains('--shown')) {
                show(targetSection); // The menu is open; close it and scroll
            } else {
                smoothScrollToElement(targetSection); // The menu is already closed; just scroll
            }
        });
    });

    document.querySelectorAll(".--previewBtn").forEach(element => {
        // Fonction pour gérer la position du cercle
        const handleCirclePosition = (event, prevElement, action) => {
            const {left, top} = element.getBoundingClientRect();
            const relX = event.pageX - (window.scrollX + left);
            const relY = event.pageY - (window.scrollY + top);

            if (prevElement.classList.contains("su_button_circle")) {
                prevElement.style.left = `${relX}px`;
                prevElement.style.top = `${relY}px`;
                prevElement.classList.remove(action === 'enter' ? 'desplode-circle' : 'explode-circle');
                prevElement.classList.add(action === 'enter' ? 'explode-circle' : 'desplode-circle');
            }
        };

        // Gestionnaire d'événement mouseenter
        element.addEventListener("mouseenter", event => {
            if (!isMenuOpen) {
                const prevElement = element.previousElementSibling;
                handleCirclePosition(event, prevElement, 'enter');
            }
        });

        // Gestionnaire d'événement mouseleave
        element.addEventListener("mouseleave", event => {
            if (!isMenuOpen) {
                const prevElement = element.previousElementSibling;
                handleCirclePosition(event, prevElement, 'leave');
            }
        });

        // Désactiver l'animation au clic
        element.addEventListener("click", () => {
            const prevElement = element.previousElementSibling;
            if (prevElement.classList.contains("su_button_circle")) {
                prevElement.classList.remove("explode-circle", "desplode-circle");
            }
        });
    });
});