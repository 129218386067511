document.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementById('contact-form');
    const submitButton = form.querySelector('button[type="submit"], input[type="submit"]');
    const statusMessages = document.getElementById('statusMessages');
    let fieldErrors = {}; // Stocke les erreurs de chaque champ avec des messages spécifiques

    const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

    const fieldNames = {
        firstname: 'Prénom',
        lastname: 'Nom',
        email: 'Email',
        phone: 'Téléphone',
        avis: 'Avis'
    };

    const formatPhoneNumber = input => {
        let rawValue = input.value.replace(/\D+/g, '');
        let formattedValue = '';
        let prefix = '';

        if (input.value.startsWith('+')) {
            prefix = '+33 ';
            rawValue = rawValue.substring(2);
        } else if (rawValue.startsWith('33')) {
            prefix = '+33 ';
            rawValue = rawValue.substring(2);
        } else if (rawValue.startsWith('0')) {
            // C'est un numéro national français
        }

        if (prefix === '+33 ') {
            // Formatage international '+33 X XX XX XX XX'
            formattedValue = rawValue.substring(0, 1) + ' ';
            rawValue = rawValue.substring(1);
        }

        for (let i = 0; i < rawValue.length; i += 2) {
            formattedValue += rawValue.substr(i, 2) + (i + 2 < rawValue.length ? ' ' : '');
        }

        input.value = prefix + formattedValue.trim();
    }

    const validateField = input => {
        const name = input.name;
        const value = input.value.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^(\+33\s[1-9](\s?\d{2}){4})|(0[1-9](\s?\d{2}){4})$/;

        const errorMessages = {
            default: `${fieldNames[name] || capitalize(name)} est manquant.`,
            email: {
                required: `${fieldNames.email} est manquant.`,
                invalid: `${fieldNames.email} n'est pas dans un format valide. Ex : contact@nomdedomaine.fr`
            },
            phone: {
                invalid: `${fieldNames.phone} n’est pas dans un format valide. Ex : 00 00 00 00 00 ou +33 0 00 00 00 00`
            }
        };

        let errorMessage = '';

        if (name === 'avis') return '';

        if (!value) {
            errorMessage = name !== 'phone' ? errorMessages[name]?.required || errorMessages.default : '';
        } else if (name === 'email' && !emailRegex.test(value)) {
            errorMessage = errorMessages.email.invalid;
        } else if (name === 'phone' && !phoneRegex.test(value)) {
            errorMessage = errorMessages.phone.invalid;
        }

        fieldErrors[name] = errorMessage;
        input.classList.toggle('input-error', !!errorMessage);

        return errorMessage;
    }

    const showSuccessMessage = () => {
        statusMessages.textContent = 'Votre message a été envoyé avec succès !';
        statusMessages.style.visibility = 'visible';
        statusMessages.style.color = 'green';
    }

    const updateErrorMessages = () => {
        const errors = Object.entries(fieldErrors).filter(([_, message]) => message);
        statusMessages.textContent = errors.length > 1 ? 'Plusieurs éléments sont manquants.'
            : errors.length === 1 ? errors[0][1]
                : '';
        statusMessages.style.visibility = errors.length ? 'visible' : 'hidden';
    }

    function clearFormInputs() {
        form.querySelectorAll('input, textarea').forEach(input => {
            input.value = ''; // Vide chaque champ
        });
    }

    const validateForm = () => {
        // Validation de chaque champ du formulaire
        Object.values(form.elements).forEach(input => {
            if (input.tagName === 'INPUT' || input.tagName === 'TEXTAREA') {
                validateField(input);
            }
        });

        updateErrorMessages();

        return !Object.values(fieldErrors).some(error => error !== '');
    }

    // Attachement de l'événement 'input' pour la validation en temps réel après la première tentative de soumission
    form.querySelectorAll('input, textarea').forEach(input => {
        input.addEventListener('input', () => {
            if (input.type === 'tel') {
                formatPhoneNumber(input);
            }
            validateField(input);
            updateErrorMessages();
        });
    });

    submitButton.addEventListener('click', (e) => {
        if (!validateForm()) {
            e.preventDefault();
        }
        else {
            setTimeout(() => {
                // e.preventDefault();
                clearFormInputs();
                showSuccessMessage();
            }, "2000");
        }
    });

    form.addEventListener('submit', (e) => {
        e.preventDefault();
    });

    document.querySelector('.--form').addEventListener('mousemove', function(e) {
        const rect = this.getBoundingClientRect();
        // Calcule la position de la souris par rapport au centre du bouton
        const x = e.clientX - rect.left - this.offsetWidth / 2;
        const y = e.clientY - rect.top - this.offsetHeight / 2;

        // Limite le mouvement du contenu à une petite fraction de cette distance pour un effet subtil
        const translateX = x * 0.15; // Réduit le facteur de déplacement pour un mouvement plus subtil
        const translateY = y * 0.15; // Idem pour l'axe Y

        // Applique le déplacement au contenu
        this.querySelector('.content').style.transform = `translate(${translateX}px, ${translateY}px)`;
    });

    document.querySelector('.--form').addEventListener('mouseleave', function() {
        // Réinitialise la position du contenu lorsqu'on quitte le bouton
        this.querySelector('.content').style.transform = 'translate(0px, 0px)';
    });
});
