import feather from 'feather-icons';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../styles/main.scss';
import './form';
import './copyright';
import './carousel';
import './animations';
import './scene';
import './contact';
import './navbar';

import { animateLinks } from './social';

gsap.registerPlugin(ScrollTrigger);

// console.log(process.env.API_KEY);

document.addEventListener('DOMContentLoaded', () => {
    feather.replace();
});

document.addEventListener('DOMContentLoaded', () => {
    const changeVMenu = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--menu', `${vh}px`);
    };

    changeVMenu();

    window.addEventListener('resize', changeVMenu);
    window.addEventListener('orientationchange', changeVMenu);
});

document.addEventListener('DOMContentLoaded', () => {
    const changeVH = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('DOMContentLoaded', changeVH);
    window.addEventListener('orientationchange', changeVH);
});

// class MonElementPersonnalise extends HTMLElement {
//     constructor() {
//         super();
//         // Logique de l'initialisation
//     }
//
//     connectedCallback() {
//         this.innerHTML = `<p>Contenu de mon élément personnalisé</p>`;
//         // Logique exécutée lorsque l'élément est ajouté au DOM
//     }
// }
//
// // Définition de l'élément personnalisé
// customElements.define('mon-element-personnalise', MonElementPersonnalise);

document.addEventListener('DOMContentLoaded', () => {
    const containers = document.querySelectorAll('.skill__content-container');

    containers.forEach(container => {
        container.addEventListener('mousemove', (e) => {
            const card = container.querySelector('.skill__content-card');
            if (card) {
                const cardRect = card.getBoundingClientRect();
                const mouseX = e.clientX - cardRect.left - (cardRect.width / 2);
                const mouseY = e.clientY - cardRect.top - (cardRect.height / 2);
                const rotateY = (18 * mouseX) / (cardRect.width / 2);
                const rotateX = -(18 * mouseY) / (cardRect.height / 2);

                // Applique les transformations via une méthode requestAnimationFrame pour optimiser la performance
                requestAnimationFrame(() => {
                    card.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
                });
            }
        });

        container.addEventListener('mouseleave', () => {
            const card = container.querySelector('.skill__content-card');
            if (card) {
                requestAnimationFrame(() => {
                    card.style.transform = 'rotateX(0) rotateY(0)';
                });
            }
        });
    });
});

document.addEventListener('DOMContentLoaded', () => {
    function getScrollbarWidth() {
        // Créer un élément conteneur
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // Forcer l'apparition de la scrollbar
        document.body.appendChild(outer);

        // Créer un élément enfant
        const inner = document.createElement('div');
        outer.appendChild(inner);

        // Calculer la différence entre la largeur du conteneur et celle de l'enfant
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        // Nettoyer en supprimant les éléments créés
        outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    }

// Utiliser la fonction
    console.log(getScrollbarWidth());
});